// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  align-items: center;
  background: #ffffff;
  display: flex;
  font-size: 0.8rem;
  height: 3.1428572rem;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 543px) {
  :host {
    font-size: 0.7rem;
  }
}
:host .copyright,
:host .version {
  padding: 10px 22px;
}
@media screen and (max-width: 543px) {
  :host .copyright {
    padding-right: 0;
  }
  .rtl :host .copyright {
    padding-left: 0;
    padding-right: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/rt-components/footer/footer.component.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,8BAAA;EACA,WAAA;AADF;AAEE;EARF;IASI,iBAAA;EACF;AACF;AACE;;EAEE,kBAAA;AACJ;AAEI;EADF;IAEI,gBAAA;EACJ;EACI;IACE,eAAA;IACA,mBAAA;EACN;AACF","sourcesContent":["@import '../../../assets/sass/variable';\r\n\r\n:host {\r\n  align-items: center;\r\n  background: #ffffff;\r\n  display: flex;\r\n  font-size: .8rem;\r\n  height: $module * 2;\r\n  justify-content: space-between;\r\n  width: 100%;\r\n  @media screen and (max-width: 543px) {\r\n    font-size: .7rem;\r\n  }\r\n\r\n  .copyright,\r\n  .version {\r\n    padding: 10px 22px;\r\n  }\r\n  .copyright {\r\n    @media screen and (max-width: 543px) {\r\n      padding-right: 0;\r\n\r\n      .rtl & {\r\n        padding-left: 0;\r\n        padding-right: 22px;\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
