import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogServiceService } from '../../../service/blog-service.service';
import { Blog } from '../../../model/blog-type';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})
export class BlogDetailComponent implements OnInit {

  public blogId;
  public blogDetail: Blog;

  constructor(private router: Router, private route: ActivatedRoute, private blogService: BlogServiceService) {
    this.blogId = route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
     this.blogService.getBlogs().subscribe((blogs: Blog[]) => {
       this.blogDetail = blogs.filter(x => x.id === +this.blogId)[0];
     })

  }

  viewBlogDetail() {

  }


}
