import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from '../../../../service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-password',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class ResetPasswordComponent implements OnInit {

  verificationCode: string;
  newPassword: string;
  confirmPassword: string;
  submissionError: string;
  submitted = false;
  resetPasswordForm: FormGroup;
  statusMessage: string;
  statusCode: string;
  isSuccessful = false;
  hide = true;
  isValid: boolean;

  constructor(private authService: AuthService, private router: Router, public formBuilder: FormBuilder) { }

  ngOnInit (): void {
    this.resetPasswordForm = this.formBuilder.group({
      verificationCode: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  get formControls() { return this.resetPasswordForm.controls; }

  private validateNewPassword() {
    this.isValid = true;
    this.newPassword = this.resetPasswordForm.value.password;
    this.confirmPassword = this.resetPasswordForm.value.confirmPassword;
    if (this.confirmPassword !== this.newPassword) {
      this.isValid = false;
      this.submissionError = 'Confirm password should match new password.';
    }

    return this.isValid;
  }

  resetPassword() {
    if (!this.validateNewPassword()) {
      return;
    }
    this.submitted = true;
    this.verificationCode = this.resetPasswordForm.value.verificationCode;
    this.newPassword = this.resetPasswordForm.value.password;
    this.authService.confirmPassword(this.verificationCode, this.newPassword,
      (err, statusCode) => {
        this.submitted = false;
        if (statusCode === AuthService.statusCodes.incompletedSigninData) {
          this.router.navigate(['forot-password']);
          this.isSuccessful = true;
        } else if (statusCode === AuthService.statusCodes.success) {
          this.isSuccessful = true;
          this.statusCode = statusCode;
          this.statusMessage = 'Password change is successful. You will be redirected to signin page within 5 seconds';
          setTimeout(() => { this.authService.signout(); }, 5000);
          return;
        } else if (statusCode === AuthService.statusCodes.unknownError) {
          this.isSuccessful = false;
          this.statusCode = statusCode;
          this.submissionError = err.message;
        }
      });
  }
}
