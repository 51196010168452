import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs/Rx';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, DBService, DownLoadService} from '../../service';
import {User} from '../../model/types';
import {Chromatogram, ChromatogramFiles, ChromatogramGraphs, SearchResult} from '../../model/analysis-details.model';
import {DropDownService} from '../../service/drop-down.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-chromatograms',
  templateUrl: './chromatograms.component.html',
  styleUrls: ['./chromatograms.component.scss']
})
export class ChromatogramsComponent implements OnInit {

  routersub: Subscription;
  analysisId = '';
  signedInUser: any;
  searchResult: SearchResult = <SearchResult>{};
  results: Chromatogram[];
  files: ChromatogramFiles[]
  url: any;
  flag = false;
  pie_chart = ''


  constructor(private activatedroute: ActivatedRoute,
              private dbService: DBService,
              private authService: AuthService,
              private http: HttpClient,
              private dropDownService: DropDownService,
              private downloadService: DownLoadService,
              private router: Router) {

    this.populateResultData()

  }


  private populateResultData() {
    this.refreshResult();
    this.dbService.singleSearch.subscribe(
        (data: SearchResult) => {
          this.searchResult = data;
          if (data.analysisStatus === 'Complete') {
            this.results = JSON.parse(this.searchResult.analysisResult);
            this.files = JSON.parse(this.searchResult.analysisResultFile);
            this.attach_graph()
            this.flag = true;
          }

        }
    );
  }

  private attach_graph() {
    for ( const result of this.results) {
      result.graphs = [];
      for (const file of this.files) {
        const file_base = this.getFilebase(file.key);
        const file_root = file_base.split('__');
        if (file.type === 'GRAPH' && result.file_name === file_root[0]) {
          const graph: ChromatogramGraphs = {
            'key'  : file.key,
            'type' : file_root[1]
          }
          console.log('graph obj ', graph);
          result.graphs.push(graph)
        }
      }
      console.log(result.graphs)
    }
  }


  refreshResult() {
    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      if (!this.signedInUser || !this.signedInUser.signedIn) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      } else {
        this.routersub = this.activatedroute.paramMap.subscribe(params => {
          this.analysisId = params.get('id');
          this.dbService.getAnalysisDetails(this.signedInUser.username, this.analysisId);
        });
      }
    });
  }

  ngOnInit() {
    // this.downloadFile('admin/us-east-2:5b0141b8-76a2-48de-84f3-725f2c479646/dropdowns.json')
  }

  getSingedURL(path: string) {
    if (/\S/.test(path)) {
      return this.downloadService.getUrl(path);
    } else {
      return ' ';
    }
  }

  public downloadFile(key: string) {
    // console.log('Key for download ', key)
    const var1 = this.downloadService.getS3Obj(key).then(data => {
      this.pie_chart = data;
      console.log('DATAAA ', data);
      const result =  new TextDecoder('utf-8').decode(data.Body);
      console.log('Chromatogram file', result)
    });
    //
    // console.log('VAR', JSON.stringify(var1))
/*    this.http.get(downloadLink).subscribe(data => {
      console.log(data);
    })*/

/*
    return this.http.get(downloadLink)
        .map((response) => {
          // @ts-ignore
          const blob = new Blob([response], { type: 'text/csv' });
          console.log(blob)
        });
*/
  }

  getFileName(key) {
    const splitted = key.split('/');
    const file =  splitted[splitted.length - 1];
    const filebase = file.split('.')[0];
    return splitted[splitted.length - 1]
  }
  getFilebase(key) {
    const splitted = key.split('/');
    const file =  splitted[splitted.length - 1];
    const filebase = file.split('.')[0];
    return filebase
  }

  getDisplayString(key) {
    return this.dropDownService.nameDescMap.get(key);
  }

  getColor(level: string): string {
    if (level === 'Green') {
      return '#C5EEB2'
    } else if (level === 'Yellow') {
      return '#F8F8A9'
    } else if (level === 'Info') {
      return '#75cff8'
    } else if (level === 'Red') {
      return '#EEC2B7'
    }
  }

}
