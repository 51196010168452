import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ContainerEvents } from '../pages/doc-upload';
import { S3 } from 'aws-sdk';
import { S3Factory } from '../../utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { User } from '../model/types';
import { AnalysisDetails, Analyte, FileObject } from '../model/analysis-details.model';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AuthNewService } from './auth-new.service';
import Amplify, { Auth, Storage } from 'aws-amplify';



@Injectable({
    providedIn: 'root'
})
export class UploadService {
    disabledValue = new BehaviorSubject<boolean>(true);

    isSubmit: boolean;
    // Observable string sources
    private uploadContainerEventSource = new Subject<ContainerEvents>();
    private fileUploadEventSource = new Subject<FileObject>();
    URL: string = environment.serverUrl + '/analysis-data';
    BUCKET: string = environment.bucket;
    updatedPath;

    // Observable string streams
    uploadContrainerEvent$ = this.uploadContainerEventSource.asObservable();
    fileUploadEvent$ = this.fileUploadEventSource.asObservable();
    private signedInUser: User;
    private region: string;
    private analysis: Analyte;
    fileCount = 0;
    progressCount = 0;
    fileProgress: number;
    fileError = new BehaviorSubject<string>('');

    submitDisable: boolean = Boolean(localStorage.setItem('submit', 'true'));

    constructor(private http: HttpClient,
        private newAuthService: AuthNewService,
        private router: Router) {
        this.region = environment.defaultRegion || 'us-east-2';
        const bucket = environment.bucket;
        Amplify.configure({
            Auth: environment.cognito,
            Storage: environment.Storage
        });
    }

    setSignedInUser(user: User) {
        this.signedInUser = user;
    }

    setAnalysisData(enterData: Analyte) {
        this.analysis = enterData;
        return false;
    }

    // Upload status updates
    publishUploadContainerEvent(event: ContainerEvents) {
        this.uploadContainerEventSource.next(event);
    }

    publishFileUploadEvent(file: FileObject) {
        this.fileUploadEventSource.next(file);
    }

    setRegion(region: string) {
        this.region = region;
    }

    private preparePutObjectRequest(file: File, region: string): S3.Types.PutObjectRequest {
        // console.log('analysis temp  Path ', this.analysis.uploadObjs[0]?.path);
        const path = this.analysis.uploadObjs[0]?.path;
        const obj = {
            Key: [path,
                file.name].join('/'),
                Bucket: this.BUCKET,
                Body: file,
                ContentType: file.type
            };
        return obj;
    }

    upload(file: File, progressCallback: (error: Error, progress: number) => void, region?: string) {
        region = region || this.region;
        this.newAuthService.getCurrentUser();
        this.newAuthService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {
            this.signedInUser = user;
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            }
            // console.log('UPLOADING FILE IN SERVICE')

            const fileUpload = this.preparePutObjectRequest(file, region);
            console.log(fileUpload);
            

            Storage.put(`${fileUpload.Key}`, fileUpload.Body, {
                // level: 'private',
                progressCallback: (progress) => {
                    this.fileProgress = Math.round((progress.loaded / progress.total) * 100)
                },
            })
            .then(result => {
                this.handleS3UploadProgress(progressCallback);
                console.log('result key', result.key);
                this.updatedPath = result.key;
                this.fileCount++;
                return result;
            })
            .catch(err => {
                console.log(err);
            });
        });
    }

    private handleS3UploadProgress
        (progressCallback: (error: Error, progress: number) => void) {
        if(this.fileProgress === 100) {
            this.disabledValue.next(false);
        }

       progressCallback(undefined, this.fileProgress);
    }

    private handleS3UploadComplete(
        progressCallback: (error: Error, progress: number, speed: number) => void) {
        return (error: Error, data: S3.ManagedUpload.SendData) => {
            if (error) {
                progressCallback(error, undefined, undefined);
            } else {
                progressCallback(error, 100, undefined);
            }
        };
    }

    cancel(s3Upload: S3.ManagedUpload) {
        s3Upload.abort();
    }

    // Added to check Commit
    submitForAnalysis(analysisDetailObj: AnalysisDetails) {

        console.log('Submit for analysis', analysisDetailObj.analytes[0].uploadObjs[0].path);
        analysisDetailObj.analysisStatus = 'NewAnalysis';
        const ppp = `public/${analysisDetailObj.analytes[0].uploadObjs[0].path}`;
        analysisDetailObj.analytes[0].uploadObjs[0].path = ppp;
        console.log('UR', this.URL)
        console.log('URL is ', analysisDetailObj)
        return this.http.post(this.URL, analysisDetailObj)
    }

}
