import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Blog } from '../model/blog-type';

@Injectable({
  providedIn: 'root'
})
export class BlogServiceService {
  // blogDetail: Blog[] = [];

  constructor(private http: HttpClient, private router: Router) { }

  // fumction to get all the blogs
  getBlogs() {
    return this.http.get('https://92jk3jjzcf.execute-api.us-east-2.amazonaws.com/dev/blog_data');
  }

}
