export enum ContainerEvents {
  Upload,
  Cancel,
  Delete
}

export interface S3ConfigParams {
  bucketName: string;
  folderPath?: string;
}

