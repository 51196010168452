import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {DropDown, DropDowns, SearchResult} from '../model/analysis-details.model';


@Injectable({
  providedIn: 'root'
})
export class DropDownService {

  analysisType_dd: DropDown[];
  analysisSubtype_dd: DropDown[];
  regressionModel_dd: DropDown[];
  sampleMatrix_dd: DropDown[];
  antiCoagulant_dd: DropDown[];
  species_dd: DropDown[];
  samplePrep_dd: DropDown[];
  normalityTestData_dd: DropDown[];
  dropDownsMap: Map<string, DropDown[]> = new Map();
  nameDescMap: Map<string, string> = new Map();

  constructor(private http: HttpClient) {
    // this. getAnalysisType();
    this.getDropDowns()
  }

  // getAnalysisType(): Observable<any> {
  //   return this.http.get('assets/data/dropdowns.json')
  // }

  getDropDowns() {
    this.http.get('assets/data/dropdowns.json').map(res => <DropDowns[]>res).subscribe(
        (data) => {
          for (const ddobj of data) {
            this.dropDownsMap.set(ddobj.key, ddobj.values);
            for (const ndobj of ddobj.values) {
              this.nameDescMap.set(ndobj.name, ndobj.desc);
            }
          }
        }
    )
  }

}
