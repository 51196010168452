// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chartCards {
  background-color: lightgoldenrodyellow;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/graph-dialog/graph-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;AACF","sourcesContent":[".chartCards {\r\n  background-color: lightgoldenrodyellow;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
