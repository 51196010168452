import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Graph } from '../../../model/small-molecule.model';


@Component({
  selector: 'app-graph-dialog',
  templateUrl: './graph-dialog.component.html',
  styleUrls: ['./graph-dialog.component.scss']
})
export class GraphDialogComponent implements OnInit {
  public refinedData;
  public processedData = []
  graph_options = [];
  graphData = [];
  public g_data: Graph;
  public activity;
  public xData;
  public label;
  optionsh: any;
  optionsl: any;
  optionsb: any;
  public subplot = [];
  highcharts;

  constructor(private dialogRef: MatDialogRef<GraphDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
    this.graphData = data.name;
    dialogRef.afterClosed().subscribe(() => {
      this.clearData();
    })

  }

  @HostListener('document:keyup.escape') onClose() {
    this.onCancel();
  }


  clearData() {
    this.graphData.length = 0;
  }

  onCancel() {
    this.dialogRef.close();
    this.clearData();
  }

  ngOnInit() {

    // for (const graph of this.graphData) {
    //   const graphObject = JSON.parse(graph);
    //   if (graphObject.type === 'HIST') {
    //     this.graph_options.push(this.histogramChart(graphObject));
    //   } else if (graphObject.type === 'GROUP_BOX') {
    //     this.BoxGraph(graphObject);
    //   } else if (graphObject.type === 'LINE') {
    //     this.graph_options.push(this.lineChart(graphObject));
    //   } else if (graphObject.type === 'GROUP') {
    //     this.graph_options.push(this.subplotChart(graphObject));

    //   } else {
    //     console.log('Error Occured while generating charts!')
    //   }
    // }

  }

}
