// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload button {
  margin-left: 5px;
}

.section-title {
  margin-bottom: 50px;
}

.file-input-btn {
  margin-bottom: 0;
}

.upload .previews {
  margin: 0 10px;
}

.upload .localsubmit {
  margin: 0 10px;
}

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
  flex-basis: 0;
}

.example-headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

mat-form-field {
  margin-right: 12px;
}

.btn {
  margin-right: 8px;
}

.mat-action-row {
  border-top-style: none;
  padding: 4px 4px 4px 4px;
}

.rt-full-width {
  width: 70%;
}

ol {
  columns: 1;
  -webkit-columns: 1;
  -moz-columns: 1;
}

li {
  margin-top: 5px;
  margin-left: 10px;
  padding: 2px;
}

.fileupload-drop-zone {
  border: 1px solid green;
  height: 50px;
}

.divLabel {
  float: left;
  border-radius: 10%;
  height: 32px;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/doc-upload/doc-upload.component.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAIE;EACE,cAAA;AADJ;;AAME;EACE,cAAA;AAHJ;;AAOA;;EAEE,aAAA;AAJF;;AAOA;EACE,8BAAA;EACA,mBAAA;AAJF;;AAOA;EACE,kBAAA;AAJF;;AAOA;EACE,iBAAA;AAJF;;AAMA;EACE,sBAAA;EACA,wBAAA;AAHF;;AAKA;EACE,UAAA;AAFF;;AAIA;EACE,UAAA;EACA,kBAAA;EACA,eAAA;AADF;;AAGA;EACE,eAAA;EACA,iBAAA;EACA,YAAA;AAAF;;AAEA;EACE,uBAAA;EACA,YAAA;AACF;;AACA;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AAEF","sourcesContent":[".upload {\r\n  button {\r\n    margin-left: 5px;\r\n  }\r\n}\r\n.section-title {\r\n  margin-bottom: 50px\r\n}\r\n\r\n.file-input-btn {\r\n  margin-bottom: 0\r\n}\r\n\r\n.upload {\r\n  .previews {\r\n    margin: 0 10px\r\n  }\r\n}\r\n\r\n.upload {\r\n  .localsubmit {\r\n    margin: 0 10px\r\n  }\r\n}\r\n\r\n.example-headers-align .mat-expansion-panel-header-title,\r\n.example-headers-align .mat-expansion-panel-header-description {\r\n  flex-basis: 0;\r\n}\r\n\r\n.example-headers-align .mat-expansion-panel-header-description {\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\nmat-form-field {\r\n  margin-right: 12px;\r\n}\r\n\r\n.btn {\r\n  margin-right: 8px;\r\n}\r\n.mat-action-row {\r\n  border-top-style:none;\r\n  padding: 4px 4px 4px 4px;\r\n}\r\n.rt-full-width {\r\n  width: 70%;\r\n}\r\nol {\r\n  columns: 1;\r\n  -webkit-columns: 1;\r\n  -moz-columns: 1;\r\n}\r\nli{\r\n  margin-top: 5px;\r\n  margin-left: 10px;\r\n  padding: 2px;\r\n}\r\n.fileupload-drop-zone{\r\n  border: 1px solid green;\r\n  height: 50px;\r\n}\r\n.divLabel{\r\n  float: left;\r\n  border-radius: 10%;\r\n  height: 32px;\r\n  color: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
