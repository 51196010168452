import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {AnalysisKey, AuthLog} from '../../../model/analysis-details.model';

@Component({
  selector: 'app-auth-log',
  templateUrl: './auth-log.component.html',
  styleUrls: ['./auth-log.component.scss']
})

export class AuthLogComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<AuthLog>;
  tableColumns: string[] = ['userId', 'activityType', 'activityDate'];

  @ViewChild(MatPaginator, {static: false}) ch_paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) ch_sort: MatSort;



  @Input() authLogs: AuthLog[] = [];

  constructor() {
  }

  ngOnInit() {
    // console.log('Detail Page ', this.authLogs)
    this.dataSource = new MatTableDataSource(this.authLogs);
    setTimeout(() => this.dataSource.paginator = this.paginator);
    setTimeout(() => this.dataSource.sort = this.sort);

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
