import {AnalysisDetails, AnalysisKey, PreviousAnalysis, SearchResult} from '../model/analysis-details.model';


export default class Utils {
    nameDescMap: Map<string, string>

    constructor(nameDescMap) {
        this.nameDescMap = nameDescMap;
    }
     public restructureSearch(data: PreviousAnalysis[]): AnalysisKey[] {
        const resultArray: AnalysisKey[] = [];
        for (const prevAnalysisObj of data) {
            const analysiskey: AnalysisKey = JSON.parse(prevAnalysisObj.analysisKey);
            const obj: AnalysisKey = {
            'userId' :          prevAnalysisObj.userId,
            'analysisId' :      prevAnalysisObj.analysisId,
            'analyteNames' :    analysiskey.analyteNames,
            'projectNames' :    this.getProjectNames(analysiskey),
            'analysisDate' :    this.getFormattedDate(prevAnalysisObj.analysisId),
            'analysisType' :    analysiskey.analysisType,
            'analysisTypeDisplay': this.getDisplayString(analysiskey.analysisType),
            'analysisSubtypeDisplay': this.getDisplayString(analysiskey.analysisSubtype)
        };
            resultArray.push(obj);
        }
        return resultArray;
    }


    private  getAnalyteNames(analysisDetails) {
        const analytes = [];
        for (const analyte of analysisDetails.analytes) {
            analytes.push(analyte.analyteName)
        }
        return analytes.toString()
    }

    private  getProjectNames(analysiskey: AnalysisKey) {
        const projectCodes: string[] = [];
        if (analysiskey.projectCodeSA) {
            projectCodes.push(analysiskey.projectCodeSA)
        }
        if (analysiskey.projectCodeMV) {
            projectCodes.push(analysiskey.projectCodeMV)
        }
        if (analysiskey.projectCode_AP) {
            projectCodes.push(analysiskey.projectCode_AP)
        }
        return projectCodes.toString()
    }

    private  getFormattedDate(analysisId) {
        const myDate = new Date(parseInt(analysisId, 10));
        return myDate.toLocaleString();
    }

    private getDisplayString(key) {
        return this.nameDescMap.get(key);
    }
}

