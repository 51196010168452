import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';


// text,email,tel,textarea,password,
@Component({
    selector: 'app-textbox',
    template: `
      <div [formGroup]="form">
        <mat-form-field class="example-full-width">
          <input matInput *ngIf="!field.multiline" style="font-weight: bold;" [attr.type]="field.type" [id]="field.name" [value]="manipulatedValue" [formControlName]="field.name">
        </mat-form-field>
      </div>
    `
})
export class TextBoxComponent implements OnInit {
    @Input() field: any = {};
    @Input() form: FormGroup;
    manipulatedValue: any;

  formBuilder: any;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }

    constructor() {}

    ngOnInit() {
      setTimeout(() => {
        this.manipulatedValue = this.field.value.toLocaleString().split(',').join(' ; ');
      })
    }


}
