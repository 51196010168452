import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../shared-service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'default-layout',
  templateUrl: 'default.component.html',
  styleUrls: ['default.component.scss'],
  providers: [ SharedService ]
})
export class DefaultLayoutComponent implements OnInit {
  pageTitle: any;
  rtl: boolean;
  @Input() openedSidebar: boolean;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  @ViewChild('childModal', { static: true }) childModal: ModalDirective;

  constructor(private _sharedService: SharedService,
              private idle: Idle,
              private keepalive: Keepalive,
              private router: Router,
              private authService: AuthService,
              ) {

    this.rtl = false;
    this.openedSidebar = true;

    _sharedService.changeEmitted$.subscribe(
      title => {
        this.pageTitle = title;
        this.openedSidebar = true;
      }
    );

    idle.setIdle(1200);
    idle.setTimeout(10);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
   });
   idle.onTimeout.subscribe(() => {
    this.childModal.hide();
    this.idleState = 'Timed out!';
    this.timedOut = true;
    this.router.navigate(['/extra-layout/signout']);
  });
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      this.childModal.show();
  });

  idle.onTimeoutWarning.subscribe((countdown) => {
    this.idleState = 'You will time out in ' + countdown + ' seconds!'
  });

  // sets the ping interval to 15 seconds
  keepalive.interval(15);

  keepalive.onPing.subscribe(() => this.lastPing = new Date());

  this.reset();
  }

  ngOnInit() {
    this.authService.getUserLoggedIn().subscribe((userLoggedIn) => {
      if (userLoggedIn) {
        this.idle.watch();
      } else {
        this.idle.stop();
      }
    })
  }

  getClasses() {
    return {
      'open-sidebar': this.openedSidebar,
      'rtl': this.rtl
    };
  }

  sidebarState() {
    this.openedSidebar = !this.openedSidebar;
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.authService.setUserLoggedIn(false);
    this.router.navigate(['extra-layout/signout']);
  }

}
