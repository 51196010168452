import { Pipe, PipeTransform } from '@angular/core';
import { FileSizeUtil } from './file-size';


/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
*/
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {

  transform(bytes: number = 0, precision: number = 2): string {
    return FileSizeUtil.transform(bytes, precision);
  }
}
