// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center {
  width: 25%;
  margin: 10px auto;
}

.main-div {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  margin-right: 10px;
}

/*.mat-icon {
  horizontal-align: right;

}*/
th {
  font-weight: bold;
  background-color: #E6E8EB;
}

table {
  width: 100%;
}

.make-gold {
  background-color: #FAF6E2;
}

.make-red {
  background-color: #F0DDDD;
}

.make-green {
  background-color: #DEEED7;
}

li {
  margin-top: 5px;
  margin-left: 10px;
  padding: 2px;
}

.mat-fab {
  min-width: 100px;
  min-height: 75px;
}

.badge {
  float: right;
}

.content {
  padding: 5px;
}

.content > mat-card {
  width: 100px;
}

.nolist {
  list-style: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/small-molecule.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AACA;EACE,kBAAA;AAEF;;AACA;;;EAAA;AAIA;EACE,iBAAA;EACA,yBAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AADA;EACE,yBAAA;AAIF;;AAFA;EACE,yBAAA;AAKF;;AAHA;EACE,yBAAA;AAMF;;AAHA;EACE,eAAA;EACA,iBAAA;EACA,YAAA;AAMF;;AAHA;EACE,gBAAA;EACA,gBAAA;AAMF;;AAHA;EACE,YAAA;AAMF;;AAFA;EACE,YAAA;AAKF;;AAFA;EACE,YAAA;AAKF;;AAFA;EACE,gBAAA;AAKF","sourcesContent":[".center{\r\n  width: 25%;\r\n  margin: 10px auto;\r\n}\r\n\r\n.main-div{\r\n  height: 30vh;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\nbutton {\r\n  margin-right: 10px;\r\n}\r\n\r\n/*.mat-icon {\r\n  horizontal-align: right;\r\n\r\n}*/\r\nth {\r\n  font-weight: bold;\r\n  background-color: #E6E8EB;\r\n}\r\ntable {\r\n  width: 100%;\r\n}\r\n.make-gold {\r\n  background-color: #FAF6E2;\r\n}\r\n.make-red {\r\n  background-color: #F0DDDD;\r\n}\r\n.make-green {\r\n  background-color: #DEEED7;\r\n}\r\n\r\nli{\r\n  margin-top: 5px;\r\n  margin-left: 10px;\r\n  padding: 2px;\r\n}\r\n\r\n.mat-fab {\r\n  min-width: 100px;\r\n  min-height: 75px;\r\n}\r\n\r\n.badge {\r\n  float: right;\r\n}\r\n\r\n\r\n.content {\r\n  padding: 5px;\r\n}\r\n\r\n.content > mat-card {\r\n  width: 100px;\r\n}\r\n\r\n.nolist {\r\n  list-style: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
