export enum FileObjectStatus {
    NotStarted,
    Uploading,
    Uploaded,
    Canceled,
    Deleted,
    Failed
}

export class FileObject {
    status = FileObjectStatus.NotStarted;
    type: string;
    name: string;
    constructor(public file: File, type: string) {
        this.type = type;
        this.name = this.file.name;
    }
}

export class Uploads {
    uploadType: string;
    index: number;
    path: string;
    files: FileObject[];
}
export class Analyte {
    analyteName: string;
    uploadObjs: Uploads[];
    tempPath: string;
    constructor(name) {
        this.analyteName = name;
        this.uploadObjs = [];
    }
}
export class AnalysisDetails {
    userId: string;
    analysisId: string;
    analysisStatus?: string;
    analysisType?: string;
    analysisSubtype?: string;
    analysisSubtype_RSG?: string;
    analysisSubtype_ADA?: string;
    report_template?: string;
    projectCodeSA?: string;
    projectCode_AP?: string;
    projectCodeMV?: string;
    regressionModel?: string;
    lts80_dialog?: string;
    lts20_dialog?: string;
    lloq?: string;
    uloq?: string;
    extraction_dialog?: string;
    benchtop_dialog?: string;
    freeze_thaw_dialog?: string;
    first_patient_dialog?: string;
    sampleMatrix?: string;
    antiCoagulant?: string;
    species?: string;
    samplePrep?: string;
    analyteNames: string[];
    sampleDate: string;
    analytes?: Analyte[];
    weighting_factor?: string;
    STD_conc_arr: string[];
    anchor_point_1?: string;
    anchor_point_2?: string;
    Dilution_QC_conc?: string;
    sample_volume?: string;
    report_id: string;
    method_id: string;
    concomitant_name: string;
    comed_conc: string;
    benchTop_Temp: string;
    noOfcycles: string;
    benchtop_hours: string;
    frozen_temp: string;
    longTerm_frozen_temp: string;
    longTerm_storage_days: string;
    protocol_id: string;
    thawing_temp: string;
    normalityTestData: string;
    screening_cutpoint: string;
    confirmatory_cutpoint: string;
    concentrationUnit: string;
    tukeyCoefficient: number;
    negativeControlValues: string;
    nabDataset: string;
    multipleAnalytes: string;
    method_type: string;
    ada_subtype: string;
    ada_subtype_cutpoint: string;
    regressionType: string;
    PL: string;
    CVThreshold: string;
    Low_QC_conc?: string;
    Mid_QC_conc?: string;
    High_QC_conc?: string;
    sponsor_name: string;
    val_project_code: string;
    sample_temp: string;
    collection_date: string;
    MRD: string;
    NCMethod: string;
    FPR: string;
    decimalPoints: string;
    outlierRemoval: string;
    autoLogTransform: string;
    LPCCriticalvalue: string;
    filterNC: string;
    filterSensitivity: string;
    logFunc: string;

    constructor(userId) {
        this.userId = userId;
        this.analysisId = Math.floor(new Date().getTime() / 1000.0).toString();
        this.analysisType = '';
        this.analytes = [];
    }
}

export interface UploadedFiles {
    type: string,
    name: string
}

export interface DropDowns {
    key: string;
    values: DropDown[]
}

export interface DropDown {
    name: string;
    desc: string;
}


export interface SearchResult {
    userId: string;
    analysisId: string;
    analysisDate?: string;
    analysisType: string;
    analysisSubtype?: string;
    analysisTypeDisplay?: string;
    analysisResult?: string;
    analysisResultFile?: string;
    analysisResultData?: string;
    AnalysisResultXlsxFile?: string;
    analysisStatus?: string;
    analysisDetails?: string;
    selectedTables?: string;
    analyteNames?: string;
    projectNames?: string;
    tableData?: string;
    tableReselection?: number;
    AnalysisResultGraph?: string;
}

export class UserData {
    data: SearchResult[]
}

export interface AnalysisData {
    data: SearchResult
}

export class PreviousAnalysisData {
    data: PreviousAnalysis[]
}

export class PreviousAnalysis {
    userId: string;
    analysisId: string;
    analysisKey: string;
}

export class AnalysisKey {
    userId: string;
    analysisId: string;
    analysisDate?: string;
    analysisType: string;
    analysisSubtype?: string;
    analyteNames?: string[];
    projectCodeSA?: string;
    projectCode_AP?: string;
    projectCodeMV?: string;
    projectNames?: string;
    analysisTypeDisplay?: string;
    analysisSubtypeDisplay?: string;

}


export class Dashboard {
    userId: string;
    analysisDate: Date;
    analyteNames: string[];
    analysisType: string;
    analysisSubtype?: string;
    projectCodeSA?: string;
    projectCodeMV?: string;
}

export class Summary {
    count: [];
    date: [];
    sumamry_array: AnalysisSummary[]

}

export class AnalysisSummary {
    userId: string;
    analysisId: string;
    overall_g: number;
    overall_y: number;
    overall_r: number;
    date: string;
    analytes: string[];
    projectCodeSA: string;
    projectCodeMV: string;
    analysisType: string;
    analysisSubtype: string;

}

export class Chromatogram {
    file_name: string;
    analysis_result: ChromatogramResult[];
    graphs: ChromatogramGraphs[];

}

export class ChromatogramResult {
    test_name: string;
    category: string;
    details: number;
}

export class ChromatogramFiles {
    type: string;
    key: string;
}

export class ChromatogramGraphs {
    type: string;
    key: string;
}

export  class Logs {
    data: AuthLog[];
    users: string[];
    file: string;
}
export class AuthLog {
    userId: string;
    activityDate: string;
    activityType: string;
}

export interface Result {
    heading: string;
    resultItem: ResultItem[];
    green: string;
    yellow: string;
    red: string;
}
export interface ResultItem {
    tabletype: string;
    item: Item[];
}
export interface Item {
    color: string;
    colorCode?: string;
    message: string[];
}
export interface Table {
    analyte_name: string;
    table_type: string;
    table_index: string;
    table_title: string;
    analysis_type: string;
    rows: string;
    valid: boolean
}
export class Templates {
    type: string;
    description: string;
    columns: TemplateDetail[];
}
export class TemplateDetail {
    name: string;
    desc: string;
    type: string;
    keys: string[];
    required: boolean;
}


