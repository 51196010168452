// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .drop-container {
        background: #fff;
        border-radius: 6px;
        height: 150px;
        width: 100%;
        box-shadow: 1px 2px 20px hsla(0,0%,4%,.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #c0c4c7;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #c0c4c7;
      }
      .upload-button {
        display: inline-block;
        border: none;
        outline: none;
        cursor: pointer;
        color: #5754a3;
      }
      .upload-button input {
        display: none;
      }
      .dropzone {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 5px;
        background: white;
        margin: 10px 0;
      }
      .dropzone.hovering {
          border: 2px solid #f16624;
          color: #dadada !important;
      }
      progress::-webkit-progress-value {
        -webkit-transition: width 0.1s ease;
        transition: width 0.1s ease;
      }
      `, "",{"version":3,"sources":["webpack://./src/app/pages/template/dynamic-form-builder/atoms/file.ts"],"names":[],"mappings":";MACM;QACE,gBAAgB;QAChB,kBAAkB;QAClB,aAAa;QACb,WAAW;QACX,yCAAyC;QACzC,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,0BAA0B;MAC5B;MACA;QACE,eAAe;QACf,gBAAgB;QAChB,cAAc;MAChB;MACA;QACE,qBAAqB;QACrB,YAAY;QACZ,aAAa;QACb,eAAe;QACf,cAAc;MAChB;MACA;QACE,aAAa;MACf;MACA;QACE,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,sBAAsB;QACtB,kBAAkB;QAClB,iBAAiB;QACjB,cAAc;MAChB;MACA;UACI,yBAAyB;UACzB,yBAAyB;MAC7B;MACA;QACE,mCAA2B;QAA3B,2BAA2B;MAC7B","sourcesContent":["\n      .drop-container {\n        background: #fff;\n        border-radius: 6px;\n        height: 150px;\n        width: 100%;\n        box-shadow: 1px 2px 20px hsla(0,0%,4%,.1);\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border: 2px dashed #c0c4c7;\n      }\n      p {\n        font-size: 16px;\n        font-weight: 400;\n        color: #c0c4c7;\n      }\n      .upload-button {\n        display: inline-block;\n        border: none;\n        outline: none;\n        cursor: pointer;\n        color: #5754a3;\n      }\n      .upload-button input {\n        display: none;\n      }\n      .dropzone {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n        border-radius: 5px;\n        background: white;\n        margin: 10px 0;\n      }\n      .dropzone.hovering {\n          border: 2px solid #f16624;\n          color: #dadada !important;\n      }\n      progress::-webkit-progress-value {\n        transition: width 0.1s ease;\n      }\n      "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
