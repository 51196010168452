import {Injectable} from '@angular/core';
import {
    Dashboard,
    SearchResult,
    Summary,
    AnalysisData,
    PreviousAnalysis, PreviousAnalysisData, AnalysisKey, AuthLog, Logs
} from '../model/analysis-details.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';
import {Table} from '../model/small-molecule.model';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DBService {
    AnalysisURL: string = environment.serverUrl + '/analysis-data';
    AuthURL: string = environment.serverUrl + '/auth-data';
    public searchResults: PreviousAnalysis[] = [];
    public recordedLogs: AuthLog[] = [];



    singleSearch = new Subject<SearchResult>();
    authLogs = new Subject<Logs>();
    userSearch = new Subject<PreviousAnalysis[]>();
    summary = new Subject<Summary>();



    constructor(private http: HttpClient,
                private authService: AuthService) {
        this.userSearch.subscribe(
            (data: PreviousAnalysis[]) => {
                // console.log('data', data);
                this.searchResults = data;
            }
        );
        // this.getDashBoardData();
        }

    getSummary(userId) {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('analysisId', null);
        params = params.append('type', 'summary');
        params = params.append('path', null);

        this.http.get(this.AnalysisURL, {params: params}).map(res => <Summary>res).subscribe(
            (data) => {
                this.summary.next(data);
            },
            (error: Error) => {
                console.log(error.message);
            }
        );

    }


    getUserAnalysis(userId) {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('analysisId', null);
        params = params.append('type', null);
        params = params.append('path', null);


        this.http.get(this.AnalysisURL, {params: params}).map(res => <PreviousAnalysisData>res).subscribe(
            (data) => {
                this.userSearch.next(data.data);
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }

    getAnalysis(userId, date, path) {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('date', date);
        params = params.append('analysisId', null);
        params = params.append('type', 'audit');
        params = params.append('path', path);

        this.http.get(this.AnalysisURL, {params: params}).map(res => <PreviousAnalysisData>res).subscribe(
            (data) => {
                // console.log('data', data);
                this.userSearch.next(data.data);
                sessionStorage.setItem('analysis-data', JSON.stringify(data.data));
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }

    getAnalysisDetails(userName, analysisId) {
        let params = new HttpParams();
        params = params.append('userId', userName);
        params = params.append('analysisId', analysisId);
        params = params.append('type', null);
        params = params.append('path', null);

        this.http.get(this.AnalysisURL, {params: params}).map(res => <AnalysisData>res).subscribe(
            (data) => {
                this.singleSearch.next(data.data);
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );


    }


    submitTableData(tableArray: Table[], userId, analysisId) {

        const selectedTables = this.filerTable(tableArray);
        const updateObj = {
            'userId': userId,
            'analysisId': analysisId,
            'analysisStatus': 'TableSelected',
            'selectedTable': JSON.stringify(selectedTables)
        }

        // const AnalysisURL = 'assets/data/input.json';
        // return this.http.get(AnalysisURL);
        return this.http.post(this.AnalysisURL, updateObj)
    }

    filerTable(tableArray: Table[]) {
        const result: any = [];
        for (const table of tableArray) {
            if (table.valid) {
                const obj = {
                    'analyte_name': table.analyte_name,
                    'table_type': table.table_type,
                    'table_index': table.table_index,
                    'table_title': table.table_title,
                    'analysis_type' : table.analysis_type,
                    'tb_title' : table.tb_title,
                    'table_subtype': table.table_subtype
                }
                result.push(obj)
            }
        }
        return result
    }

    public getDashBoardData(): Dashboard[] {
        const dashBoardObj: Dashboard[] = [];
        for (const obj of this.searchResults) {
            const analysisDetails: AnalysisKey = JSON.parse('obj.key')
            const dbobj: Dashboard = {
                'userId' : obj.userId,
                'analysisDate' : new Date( parseInt(obj.analysisId, 10)),
                'analyteNames': this.getAnalyteNames(analysisDetails),
                'analysisType': analysisDetails.analysisType,
                'analysisSubtype': analysisDetails.analysisSubtype,
                'projectCodeSA': 'analysisDetails.projectCodeSA',
                'projectCodeMV': 'analysisDetails.projectCodeMV'
            }
            dashBoardObj.push(dbobj)
        }
        return dashBoardObj
    }

    public  getSAProjectCodes(): any[] {
        const mySet = new Set();
        const dashboardObjs = this.getDashBoardData();
        for (const obj of dashboardObjs) {
            if (obj.projectCodeSA) {
                mySet.add(obj.projectCodeSA)
            }
        }
        return Array.from(mySet.values());
    }

    public  getMVProjectCodes(): any[] {
        const mySet = new Set();
        const dashboardObjs = this.getDashBoardData();
        for (const obj of dashboardObjs) {
            if (obj.projectCodeMV) {
                mySet.add(obj.projectCodeMV)
            }
        }
        return Array.from(mySet.values());
    }

    public getAnalyteNames(analysisDetails) {
        const analytes: string[] = [];
        for (const analyte of analysisDetails.analytes) {
            analytes.push(analyte.analyteName)
        }
        return analytes
    }

    // Authentication
    recordAuthEvent(event: string) {
        const data = {'event': event};
        return this.http.post(this.AuthURL, data);
    }


    getAuthData(userId, date, path: string) {
        console.log('user', userId);
        console.log('path', path);
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('date', date);
        params = params.append('path', path);
        this.http.get(this.AuthURL, {params: params}).map(res => <Logs>res).subscribe(
            (logs) => {
                // console.log(logs);
                this.authLogs.next(logs)
                sessionStorage.setItem('login-data', JSON.stringify(logs));
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }


}
