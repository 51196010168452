import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class MainMenuService {
    constructor(private http: HttpClient) {
    }

    public getData(userid: string, rank: string) {
        let URL = 'assets/data/main-menu.json';
        if (rank === 'Superviser') {
           URL = 'assets/data/admin-main-menu.json';
        }
        return this.http.get(URL);
    }

    public handleError(error: any) {
        return observableThrowError(error.error || 'Server Error');
    }
}
