// For redthread-cutpoint environment
// Front-end bucket: site-redthreadcutpoint-contents
export const environment = {
  production: false,
  serverUrl: 'https://14vgud4763.execute-api.us-east-2.amazonaws.com/dev',
  bucket: 'redthread-cutpoint-upload',
  userPool: {
    UserPoolId: 'us-east-2_n79DkJsLd',
        ClientId: '354ea57usrimojtaiv6k3sbr08',
        region: 'us-east-2'
  },
  identityPoolId: 'us-east-2:8ac2c966-1226-4734-88e8-69ac4e6cdcb3',

  defaultRegion: 'us-east-2',
    cognito: {
      identityPoolId: 'us-east-2:8ac2c966-1226-4734-88e8-69ac4e6cdcb3', //REQUIRED - Amazon Cognito Identity Pool ID
      region: 'us-east-2', // REQUIRED - Amazon Cognito Region
      userPoolId: 'us-east-2_n79DkJsLd', //OPTIONAL - Amazon Cognito User Pool ID
      userPoolWebClientId: '354ea57usrimojtaiv6k3sbr08', //OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
      AWSS3: {
        bucket: 'redthread-cutpoint-upload', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      }
    }
};

const awsconfig = {
  'aws_project_region': 'us-east-2',
  'aws_cognito_region': 'us-east-2',
  'aws_user_pools_id': 'us-east-2_n79DkJsLd',
  'aws_user_pools_web_client_id': '354ea57usrimojtaiv6k3sbr08',
  'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;